<template>
  <div class="flex">
    <div class="bg-mask"></div>
    <div class="bg-login"></div>
    <div class="m-auto mt-52 w-1/2 z-10 ">
      <img src="../assets/images/logo/logo.png" alt="">
    </div>
    <PhoneCheck @phone-check="checkPhone" v-if="!isPhoneSend"/>
    <PhoneVerify v-else/>
  </div>

</template>

<script>
import PhoneCheck from "../components/signup/PhoneCheck";
import PhoneVerify from "../components/signup/PhoneVerify";
export default {
  name: "realEstateVerify",
  components :{
    PhoneCheck,
    PhoneVerify
  },
  data(){
    return{
      isPhoneSend : false
    }
  },
  methods:{
    checkPhone(data){
      this.isPhoneSend = data
    }
  },
  mounted() {
   /* let verify_id = localStorage.getItem('verify_id')
    if (verify_id != '' && verify_id != undefined){
      this.$router.push({name : 'Signup'})
    }*/
  }
}
</script>

<style scoped>
/*.blur-box{
  top: 6rem;
}*/
</style>