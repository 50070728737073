<template>
  <div class="blur-box">
    <p class="text-center pt-4 pb-10 font-medium text-white">کد تایید را وارد کنید</p>
    <label class="block">
      <span class="text-white opacity-50 text-sm">کد پیامک شده به شماره <span class="font-bold">{{ phone }}</span> را وارد کنید</span>
      <input
          type="number"
          pattern="\d*"
          class="mt-0 block text-white w-full py-3 px-0.5 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 "
          v-model="code"
          :class="isError ? 'border-red-500 focus:border-red-500' : 'border-white focus:border-white' "
      >
    </label>
    <span
        v-if="isError"
        class="text-sm text-red-500 text-right inline-block mt-1"
    >{{ error }}</span>
    <a @click.prevent="verifyCode" class="btn-custom mt-11">ورود</a>
    <span class="btn-custom-outline mt-5 ">
        <vac
            :left-time="timer"
            ref="resendSmsCode"
        >
          <template
              v-slot:process="timer">
            <span>{{ `${timer.timeObj.ceil.s} ثانیه صبر کنید ` }}</span>
          </template>
          <template
              v-slot:finish>
            <span @click="resendCode">درخواست مجدد</span>
          </template>
        </vac>
      </span>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from "../../plugins/axios";
import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.use(vueAwesomeCountdown, 'vac')
export default {
  name: "PhoneVerify",
  data() {
    return {
      phone: this.$store.state.auth.auth.phone,
      code: null,
      error: null,
      isError: false,
      timer: 120000
    }
  },
  methods: {
    verifyCode() {
      axios.post('/verification/verify', {
        phone: this.phone,
        code: this.code
      }).then(async ({data}) => {
        if (data.status) {
          if (!data.data.login) {
            await this.$store.commit('auth/SET_USER_TOKEN', data.data.token)
            await this.$router.push({name: 'Signup'})
          } else {
            this.$toast.error('این شماره تلفن قبلا ثبت شده است و به صفحه ورود هدایت می شوید')
            setTimeout(() => {
              this.$router.push({name: 'LoginMode'})
            }, 3000)
          }
        } else {
          this.isError = true;
          this.error = data.message;
        }
      }).catch((error) => {
        this.isError = true;
        this.error = error.message
      })
    },
    resendCode() {
      axios.post('/verification/request', {
        phone: this.phone
      }).then(({data}) => {
        if (data.status === true) {
          this.$toast.open('کد با موفقیت برای شما ارسال شد')
          this.$refs.resendSmsCode.startCountdown('restart')
          this.isError = false;
          this.error = '';
        }
      }).catch((error) => {
        this.isError = true;
        this.error = error.message
      })
    },
  },
  computed: {},
  created() {
  }
}
</script>

<style scoped>

</style>